@charset "utf-8";

/* ==============================================
トップページ
============================================== */
#roomManege {
	position: relative;
	max-width: 750px;
	width: 100%;
	height: 100%;
  background-color: #a5d404;
	padding: 207px 0 0;
	margin: 0 auto;

	&::before{
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 372px;
		background-image: url(/images/common/deco_01@2x.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		z-index: 1;
	}

	@include mq(md) {
		@include props(pt, 207, 750);

		&::before{
			@include props(h, 372, 750);
		}
	}

	.logo {
		position: absolute;
		top: 75px;
		left: 50%;
		transform: translateX(-50%);
		max-width: 239px;
		width: 100%;
		margin: 0 auto 56px;
		z-index: 2;

		@include mq(md) {
			@include props(t, 75, 750);
			@include props(w, 239, 750);
			@include props(mb, 56, 750);
		}
	}

	.link{
		position: absolute;
		top: 40px;
		right: 40px;
		width: 40px;
		height: 49px;
		z-index: 3;

		@include mq(md) {
			@include props(t, 40, 750);
			@include props(r, 40, 750);
			@include props(w, 40, 750);
			@include props(h, 49, 750);
		}
	}

	.container {
		position: relative;
		width: 100%;
		min-height: 1230px;
		height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
		background-color: #fff;
		padding: 180px 0 0;
		margin: 0 auto;
		border-radius: 600px 600px 0 0;

		&::after{
			content: "";
			display: block;
			position: absolute;
			bottom: 0;
			right: 0;
			width: 240px;
			height: 103px;
			background-image: url(/images/common/deco_02@2x.png);
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}

		@include mq(md) {
			@include props(mh, 1230, 750);
			@include props(pt, 180, 750);
			border-radius: 300px 300px 0 0;
			//@include props(pb, 152, 750);

			&::after{
				@include props(w, 240, 750);
				@include props(h, 103, 750);
			}
		}
	}

	.roomManegeWrap {
		position: relative;
		max-width: 550px;
		width: 100%;
		margin: 0 auto 194px;

		&:last-of-type {
			margin-bottom: 152px;
		}

		@include mq(md) {
			@include props(w, 550, 750);
			@include props(mb, 194, 750);

			&:last-of-type {
				@include props(mb, 152, 750);
			}
		}

		.roomManegeWrapTit {
			max-width: 372px;
			width: 100%;
			margin: 0 auto 60px;

			img {
				margin: 0 auto;
			}

			@include mq(md) {
				@include props(w, 372, 750);
				@include props(mb, 60, 750);
			}
		}

		.img {
			margin: 0 auto 10px;
		}

		.text {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			opacity: 0;
			visibility: hidden;
			transition: opacity .5s, visibility .5s;

			&.isShow {
				opacity: 1;
				visibility: visible;
			}
		}
		#resultTxt1{
			@include props(w, 93, 750);
			@include props(t, -100, 750);
		}
		#resultTxt2{
			@include props(w, 56, 750);
			@include props(t, -122, 750);
		}

		select,
		input {
			display: block;
		}

		.formSelect{
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 30px;
        margin: auto;
        width: 28px;
        height: 15px;
				background-image: url(/images/common/select_icon.svg);
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;

        @include mq(md) {
					@include props(r, 30, 750);
					@include props(w, 28, 750);
					@include props(h, 15, 750);
        }
      }
		}
		select {
			max-width: 100%;
			width: 100%;
			@include fz_vw(40);
			color: #c7c7c7;
			@include placeholder(#c7c7c7);
			text-align: center;
			text-align:-webkit-center;
			text-align-last: center;
			background-color: #f4f4f4;
			border: none;
			-webkit-appearance: none; 
			padding: 32px 10px;
			margin: 0 auto 52px;

			&.isShow {
				color: #707070;
			}

			@include mq(md) {
				@include props(pt, 32, 750);
				@include props(pb, 32, 750);
				@include props(pr, 10, 750);
				@include props(pl, 10, 750);
				@include props(mb, 52, 750);
			}
		}

		.btnBox{
			position: relative;
			max-width: 460px;
			width: 100%;
			margin: 0 auto;
			z-index: 1;

			&:not(.disabled){
				&:hover{
					input[type="button"]{
						transform: translateY(14px);
					}
					input[type="submit"]{
						transform: translateY(14px);
					}
				}
			}
			
			&.active{
				&::after{
					background-color: #c7c7c7;
				}
				input[type="button"]{
					background-color: #868686;
					transform: translateY(0);
				}
			}
	
			@include mq(md) {
				@include props(w, 460, 750);

				&:hover{
					input[type="button"]{
						transform: translateY(1.86667vw);
					}
					input[type="submit"]{
						transform: translateY(1.86667vw);
					}
				}
			}

			&::after{
				content: "";
				position: absolute;
				top: 14px;
				left: 0;
				display: block;
				width: 100%;
				height: 95px;
				border-radius: 48px;
				background-color: #d9ea9d;
				z-index: -1;
	
				@include mq(md) {
					@include props(w, 460, 750);
					@include props(h, 95, 750);
					@include props(t, 14, 750);
				}
			}
			input[type="button"],
			input[type="submit"] {
				max-width: 460px;
				width: 100%;
				height: 95px;
				border: none;
				border-radius: 48px;
				background-image: url(/images/common/btn_txt.svg);
				background-position: center;
				background-repeat: no-repeat;
				background-size: 40.43%;
				background-color: #a5d401;
				padding: 0;
				transition: transform .5s;

				&#csvBtn{
					background-image: url(/images/common/btn_txt_02.svg);
					background-size: 54.56%;
				}
	
				@include mq(md) {
					@include props(w, 460, 750);
					@include props(h, 95, 750);
				}
			}
		}
	}

	.barCodeModal {
		position: fixed;
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 100%;
		width: 100%;
		height: 100vh;
		top: 0;
		left: 0;
		z-index: 10000;
		background-color: #fff;
		opacity: 0;
		visibility: hidden;
		transition: opacity .5s, visibility .5s;
		backface-visibility: hidden;

		&.isShow {
			opacity: 1;
			visibility: visible;
		}

		.barCodeModalClose {
			position: relative;
			cursor: pointer;
			z-index: 0;
			max-width: 380px;
			width: 100%;
			height: 95px;
			margin: 0 auto;
			z-index: 1;

			&::after{
				content: "";
				position: absolute;
				top: 14px;
				left: 0;
				display: block;
				width: 100%;
				height: 100%;
				border-radius: 48px;
				background-color: #d9ea9d;
				z-index: -1;
			}

			&:hover{
				.barCodeModalCloseIcon{
					transform: translateY(14px);
				}
			}

			@include mq(md) {
				@include props(w, 380, 750);
				@include props(h, 95, 750);

				&::after{
					@include props(t, 14, 750);
				}
				&:hover{
					.barCodeModalCloseIcon{
						transform: translateY(1.86667vw);
					}
				}
			}

			.barCodeModalCloseIcon{
				position: relative;
				width: 100%;
				height: 100%;
				background-color: #a5d401;
				border-radius: 48px;
				z-index: 1;
				transition: transform .5s;
				@include props(p, 32);

				img {
					width: 88px;
					margin: 0 auto;
				}

				@include mq(md) {
					@include props(p, 32, 750);

					img {
						@include props(w, 88, 750);
					}
				}
			}
		}

		.barCodeModalBox {
			max-width: 1320px;
			width: 100%;
			padding: 0;
			margin: 0 auto;
			background-color: #fff;
			z-index: 1;

			.img{
				margin: 0 auto 128px;
			}
			img{
				margin: 0 auto;
			}

			@include mq(md) {
				.img{
					@include props(mb, 128, 750);
				}
			}
		}
	}
}