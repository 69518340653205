@charset "utf-8";

/* ======================================================================
基本設定
===================================================================== */

* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */

#responsibleCheck {
  width: 1px;
  height: 0;
  @include mq(md) {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */

.pcOnly {
  @include mq(md) {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
  @include mq(md) {
    display: block !important;
  }
}

html {
  font-size: $mainRemFontSize;
}

body {
  position: relative;
  font-family: $base-font;
  font-size: 1.6rem;
  font-weight: normal;
  color: #555;
  background-color: #fff;
}

/* ハイトカラー消去
------------------------------------------------------------- */

.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;
  article {
    section {
      display: block;
      width: 100%;
      position: relative;
    }
  }
}

.container {
  max-width: 1200px;
  padding: 20px;
  margin: auto;
}

a {
  color: #555;
}
/* 共通デザイン
------------------------------------------------------------- */
.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.board-row:after {
  clear: both;
  content: '';
  display: table;
}

.status {
  margin-bottom: 10px;
}
.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}

/* アンカーリンクデザイン
------------------------------------------------------------- */
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */