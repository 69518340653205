@charset "UTF-8";
/* ================================================================================
値の定義
================================================================================ */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
@import url("https://fonts.googleapis.com/css2?family=Sawarabi+Gothic&display=swap");
/* ================================================================================
importファイル
================================================================================ */
/* ================================================================================
VW設定
================================================================================ */
/*PC設定*/
/*タブレット設定*/
/*SP設定*/
/*フォントサイズ*/
/*余白計算*/
/*余白計算（一括指定）*/
/*Placeholder*/
/* EXTEND------------------------------------- */
/* ===================================
リセット
=================================== */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  vertical-align: baseline;
}

html {
  text-size-adjust: 100%;
}

body {
  *font: x-small;
  color: #555;
  font-size: 62.5%;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  body {
    font-size: 100%;
  }
}

html,
body {
  height: 100%;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

fieldset {
  margin: 0 2px;
  border: 1px solid #999;
  padding: .35em .625em .75em;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:link {
  color: #000;
  outline: none;
}

a:visited {
  color: #000;
  outline: none;
}

a:hover {
  text-decoration: none;
  outline: none;
}

a:focus {
  text-decoration: none;
}

ins {
  color: #000;
  background: #ff9;
  text-decoration: none;
}

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted inherit;
  cursor: help;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

table th,
table td {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

hr {
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  display: block;
  overflow: visible;
  border-top: 1px solid #999;
}

img {
  max-width: 100%;
  display: block;
  font-size: 0;
  line-height: 0;
  height: auto;
  vertical-align: bottom;
}

input,
select {
  vertical-align: middle;
}

button,
select {
  text-transform: none;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

select::-ms-expand {
  display: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: initial;
}

input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'],
button {
  -webkit-appearance: none;
}

label {
  font-weight: normal;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

input {
  padding: 0;
}

input::-ms-clear, input::-ms-reveal {
  visibility: hidden;
}

[type='checkbox'],
[type='radio'] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}

:-moz-placeholder {
  color: #ccc;
}

::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[aria-busy='true'] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  margin: 0;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input,
textarea,
select {
  font-size: inherit;
  font-weight: inherit;
}

[hidden][aria-hidden='false'] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden='false']:focus {
  clip: auto;
}

strong {
  font-weight: bold;
}

/* =========================================
印刷時の定義
========================================== */
＠media print section {
  display: block;
  page-break-before: always;
}

＠media print pre,
＠media print code {
  page-break-after: always;
}

@page {
  ＠media print {
    margin-top: .4cm;
    margin: .5cm;
  }
}

＠media print p,
＠media print a,
＠media print code,
＠media print pre {
  word-wrap: break-word;
}

＠media print pre a[href]:after {
  content: "";
}

＠media print pre abbr[title]:after {
  content: "";
}

＠media print pre .ir a:after,
＠media print pre a[href^='javascript:']:after,
＠media print pre a[href^='#']:after {
  content: '';
}

＠media print .noPrint {
  display: none !important;
}

＠media print body {
  width: 1280px;
  font-size: 12pt;
  transform: scale(0.8);
  transform-origin: 0 0;
  -webkit-print-color-adjust: exact;
}

＠media print body .continer {
  padding-top: 0;
}

/* ======================================================================
基本設定
===================================================================== */
* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */
#responsibleCheck {
  width: 1px;
  height: 0;
}

@media screen and (max-width: 767px) {
  #responsibleCheck {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */
@media screen and (max-width: 767px) {
  .pcOnly {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .spOnly {
    display: block !important;
  }
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  color: #555;
  background-color: #fff;
}

/* ハイトカラー消去
------------------------------------------------------------- */
.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;
}

main article section {
  display: block;
  width: 100%;
  position: relative;
}

.container {
  max-width: 1200px;
  padding: 20px;
  margin: auto;
}

a {
  color: #555;
}

/* 共通デザイン
------------------------------------------------------------- */
.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.board-row:after {
  clear: both;
  content: '';
  display: table;
}

.status {
  margin-bottom: 10px;
}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}

/* アンカーリンクデザイン
------------------------------------------------------------- */
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
/* =====================================
ヘッダー
===================================== */
/* =====================================================
フッター
===================================================== */
#footer {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
}

#footer small {
  display: block;
  font-size: 1.2rem;
  font-size: 0.87848vw;
  text-align: center;
  color: #666;
}

@media screen and (min-width: 1366px) {
  #footer small {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  #footer small {
    font-size: 1.6vw;
  }
}

/* 見出し装飾--------------------------- */
/* アニメーション関連---------------------- */
/* ボタン装飾------------------------------ */
button {
  outline: none;
}

.columnLayout {
  display: flex;
  justify-content: space-between;
}

.columnLayout .col1 {
  flex-basis: 100%;
}

.columnLayout .col2 {
  flex-basis: 50%;
}

.columnLayout .col3 {
  flex-basis: 33%;
}

.columnLayout .col4 {
  flex-basis: 25%;
}

.columnLayout .col5 {
  flex-basis: 20%;
}

.columnLayout .col6 {
  flex-basis: 16%;
}

.columnLayout .col7 {
  flex-basis: 14%;
}

.columnLayout .col8 {
  flex-basis: 12%;
}

/************************************
トップページ
***********************************/
/* ==============================================
トップページ
============================================== */
#roomManege {
  position: relative;
  max-width: 750px;
  width: 100%;
  height: 100%;
  background-color: #a5d404;
  padding: 207px 0 0;
  margin: 0 auto;
}

#roomManege::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 372px;
  background-image: url(/images/common/deco_01@2x.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  #roomManege {
    padding-top: 27.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege {
    padding-top: 207px;
  }
}

@media screen and (max-width: 767px) {
  #roomManege::before {
    height: 49.6vw;
    max-height: 372px;
  }
}

#roomManege .logo {
  position: absolute;
  top: 75px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 239px;
  width: 100%;
  margin: 0 auto 56px;
  z-index: 2;
}

@media screen and (max-width: 767px) {
  #roomManege .logo {
    top: 10vw;
    width: 31.86667vw;
    max-width: 239px;
    margin-bottom: 7.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .logo {
    top: 75px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .logo {
    margin-bottom: 56px;
  }
}

#roomManege .link {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 40px;
  height: 49px;
  z-index: 3;
}

@media screen and (max-width: 767px) {
  #roomManege .link {
    top: 5.33333vw;
    right: 5.33333vw;
    width: 5.33333vw;
    max-width: 40px;
    height: 6.53333vw;
    max-height: 49px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .link {
    top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .link {
    right: 40px;
  }
}

#roomManege .container {
  position: relative;
  width: 100%;
  min-height: 1230px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  background-color: #fff;
  padding: 180px 0 0;
  margin: 0 auto;
  border-radius: 600px 600px 0 0;
}

#roomManege .container::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 240px;
  height: 103px;
  background-image: url(/images/common/deco_02@2x.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  #roomManege .container {
    min-height: 164vw;
    padding-top: 24vw;
    border-radius: 300px 300px 0 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .container {
    min-height: 1230px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .container {
    padding-top: 180px;
  }
}

@media screen and (max-width: 767px) {
  #roomManege .container::after {
    width: 32vw;
    max-width: 240px;
    height: 13.73333vw;
    max-height: 103px;
  }
}

#roomManege .roomManegeWrap {
  position: relative;
  max-width: 550px;
  width: 100%;
  margin: 0 auto 194px;
}

#roomManege .roomManegeWrap:last-of-type {
  margin-bottom: 152px;
}

@media screen and (max-width: 767px) {
  #roomManege .roomManegeWrap {
    width: 73.33333vw;
    max-width: 550px;
    margin-bottom: 25.86667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .roomManegeWrap {
    margin-bottom: 194px;
  }
}

@media screen and (max-width: 767px) {
  #roomManege .roomManegeWrap:last-of-type {
    margin-bottom: 20.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .roomManegeWrap:last-of-type {
    margin-bottom: 152px;
  }
}

#roomManege .roomManegeWrap .roomManegeWrapTit {
  max-width: 372px;
  width: 100%;
  margin: 0 auto 60px;
}

#roomManege .roomManegeWrap .roomManegeWrapTit img {
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #roomManege .roomManegeWrap .roomManegeWrapTit {
    width: 49.6vw;
    max-width: 372px;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .roomManegeWrap .roomManegeWrapTit {
    margin-bottom: 60px;
  }
}

#roomManege .roomManegeWrap .img {
  margin: 0 auto 10px;
}

#roomManege .roomManegeWrap .text {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s, visibility .5s;
}

#roomManege .roomManegeWrap .text.isShow {
  opacity: 1;
  visibility: visible;
}

#roomManege .roomManegeWrap #resultTxt1 {
  width: 12.4vw;
  max-width: 93px;
  top: -13.33333vw;
}

@media screen and (min-width: 1366px) {
  #roomManege .roomManegeWrap #resultTxt1 {
    top: -100px;
  }
}

#roomManege .roomManegeWrap #resultTxt2 {
  width: 7.46667vw;
  max-width: 56px;
  top: -16.26667vw;
}

@media screen and (min-width: 1366px) {
  #roomManege .roomManegeWrap #resultTxt2 {
    top: -122px;
  }
}

#roomManege .roomManegeWrap select,
#roomManege .roomManegeWrap input {
  display: block;
}

#roomManege .roomManegeWrap .formSelect {
  position: relative;
}

#roomManege .roomManegeWrap .formSelect::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  margin: auto;
  width: 28px;
  height: 15px;
  background-image: url(/images/common/select_icon.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@media screen and (max-width: 767px) {
  #roomManege .roomManegeWrap .formSelect::after {
    right: 4vw;
    width: 3.73333vw;
    max-width: 28px;
    height: 2vw;
    max-height: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .roomManegeWrap .formSelect::after {
    right: 30px;
  }
}

#roomManege .roomManegeWrap select {
  max-width: 100%;
  width: 100%;
  font-size: 4rem;
  font-size: 2.92826vw;
  color: #c7c7c7;
  text-align: center;
  text-align: -webkit-center;
  text-align-last: center;
  background-color: #f4f4f4;
  border: none;
  -webkit-appearance: none;
  padding: 32px 10px;
  margin: 0 auto 52px;
}

@media screen and (min-width: 1366px) {
  #roomManege .roomManegeWrap select {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) {
  #roomManege .roomManegeWrap select {
    font-size: 5.33333vw;
  }
}

#roomManege .roomManegeWrap select::placeholder {
  color: #c7c7c7;
}

#roomManege .roomManegeWrap select:-ms-input-placeholder {
  color: #c7c7c7;
}

#roomManege .roomManegeWrap select::-ms-input-placeholder {
  color: #c7c7c7;
}

#roomManege .roomManegeWrap select.isShow {
  color: #707070;
}

@media screen and (max-width: 767px) {
  #roomManege .roomManegeWrap select {
    padding-top: 4.26667vw;
    padding-bottom: 4.26667vw;
    padding-right: 1.33333vw;
    padding-left: 1.33333vw;
    margin-bottom: 6.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .roomManegeWrap select {
    padding-top: 32px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .roomManegeWrap select {
    padding-bottom: 32px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .roomManegeWrap select {
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .roomManegeWrap select {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .roomManegeWrap select {
    margin-bottom: 52px;
  }
}

#roomManege .roomManegeWrap .btnBox {
  position: relative;
  max-width: 460px;
  width: 100%;
  margin: 0 auto;
  z-index: 1;
}

#roomManege .roomManegeWrap .btnBox:not(.disabled):hover input[type="button"] {
  transform: translateY(14px);
}

#roomManege .roomManegeWrap .btnBox:not(.disabled):hover input[type="submit"] {
  transform: translateY(14px);
}

#roomManege .roomManegeWrap .btnBox.active::after {
  background-color: #c7c7c7;
}

#roomManege .roomManegeWrap .btnBox.active input[type="button"] {
  background-color: #868686;
  transform: translateY(0);
}

@media screen and (max-width: 767px) {
  #roomManege .roomManegeWrap .btnBox {
    width: 61.33333vw;
    max-width: 460px;
  }
  #roomManege .roomManegeWrap .btnBox:hover input[type="button"] {
    transform: translateY(1.86667vw);
  }
  #roomManege .roomManegeWrap .btnBox:hover input[type="submit"] {
    transform: translateY(1.86667vw);
  }
}

#roomManege .roomManegeWrap .btnBox::after {
  content: "";
  position: absolute;
  top: 14px;
  left: 0;
  display: block;
  width: 100%;
  height: 95px;
  border-radius: 48px;
  background-color: #d9ea9d;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #roomManege .roomManegeWrap .btnBox::after {
    width: 61.33333vw;
    max-width: 460px;
    height: 12.66667vw;
    max-height: 95px;
    top: 1.86667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .roomManegeWrap .btnBox::after {
    top: 14px;
  }
}

#roomManege .roomManegeWrap .btnBox input[type="button"],
#roomManege .roomManegeWrap .btnBox input[type="submit"] {
  max-width: 460px;
  width: 100%;
  height: 95px;
  border: none;
  border-radius: 48px;
  background-image: url(/images/common/btn_txt.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40.43%;
  background-color: #a5d401;
  padding: 0;
  transition: transform .5s;
}

#roomManege .roomManegeWrap .btnBox input[type="button"]#csvBtn,
#roomManege .roomManegeWrap .btnBox input[type="submit"]#csvBtn {
  background-image: url(/images/common/btn_txt_02.svg);
  background-size: 54.56%;
}

@media screen and (max-width: 767px) {
  #roomManege .roomManegeWrap .btnBox input[type="button"],
  #roomManege .roomManegeWrap .btnBox input[type="submit"] {
    width: 61.33333vw;
    max-width: 460px;
    height: 12.66667vw;
    max-height: 95px;
  }
}

#roomManege .barCodeModal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s, visibility .5s;
  backface-visibility: hidden;
}

#roomManege .barCodeModal.isShow {
  opacity: 1;
  visibility: visible;
}

#roomManege .barCodeModal .barCodeModalClose {
  position: relative;
  cursor: pointer;
  z-index: 0;
  max-width: 380px;
  width: 100%;
  height: 95px;
  margin: 0 auto;
  z-index: 1;
}

#roomManege .barCodeModal .barCodeModalClose::after {
  content: "";
  position: absolute;
  top: 14px;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 48px;
  background-color: #d9ea9d;
  z-index: -1;
}

#roomManege .barCodeModal .barCodeModalClose:hover .barCodeModalCloseIcon {
  transform: translateY(14px);
}

@media screen and (max-width: 767px) {
  #roomManege .barCodeModal .barCodeModalClose {
    width: 50.66667vw;
    max-width: 380px;
    height: 12.66667vw;
    max-height: 95px;
  }
  #roomManege .barCodeModal .barCodeModalClose::after {
    top: 1.86667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .barCodeModal .barCodeModalClose::after {
    top: 14px;
  }
}

@media screen and (max-width: 767px) {
  #roomManege .barCodeModal .barCodeModalClose:hover .barCodeModalCloseIcon {
    transform: translateY(1.86667vw);
  }
}

#roomManege .barCodeModal .barCodeModalClose .barCodeModalCloseIcon {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #a5d401;
  border-radius: 48px;
  z-index: 1;
  transition: transform .5s;
  padding: 2.34261vw;
}

@media screen and (min-width: 1366px) {
  #roomManege .barCodeModal .barCodeModalClose .barCodeModalCloseIcon {
    padding: 32px;
  }
}

#roomManege .barCodeModal .barCodeModalClose .barCodeModalCloseIcon img {
  width: 88px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #roomManege .barCodeModal .barCodeModalClose .barCodeModalCloseIcon {
    padding: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .barCodeModal .barCodeModalClose .barCodeModalCloseIcon {
    padding: 32px;
  }
}

@media screen and (max-width: 767px) {
  #roomManege .barCodeModal .barCodeModalClose .barCodeModalCloseIcon img {
    width: 11.73333vw;
    max-width: 88px;
  }
}

#roomManege .barCodeModal .barCodeModalBox {
  max-width: 1320px;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  background-color: #fff;
  z-index: 1;
}

#roomManege .barCodeModal .barCodeModalBox .img {
  margin: 0 auto 128px;
}

#roomManege .barCodeModal .barCodeModalBox img {
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #roomManege .barCodeModal .barCodeModalBox .img {
    margin-bottom: 17.06667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1366px) {
  #roomManege .barCodeModal .barCodeModalBox .img {
    margin-bottom: 128px;
  }
}
