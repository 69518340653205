@charset "utf-8";

/* =====================================================
フッター
===================================================== */
#footer{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;

  small{
    display: block;
    @include fz_vw(12);
    text-align: center;
    color: #666;
  }
}